<template>
  <div class="disclosure">
    <h1>Acura Charging Plan</h1>
    <h2>Promotion Terms and Conditions</h2>
    <p class="primary-color"><strong>Effective as of October 2024</strong></p>
    <p>
      Electrify America, LLC (“Electrify America”) and American Honda Motor Co., Inc. (“Honda”) are pleased to provide
      this promotional charging program (“Promotional Plan”) to drivers of all new Acura battery electric vehicles
      purchased or leased from an authorized Acura dealer. By clicking “Agree” below, you agree to participate in this
      promotion, subject to your compliance with these terms and conditions (“Promotional Plan Terms”).
    </p>
    <p>
      Participation requires the Acura EV app, enrollment in the Acura Charging Plan, and acceptance of the Electrify
      America
      <router-link class="medium" :to="{ name: 'terms-en' }">Terms of Use</router-link> and
      <router-link class="medium" :to="{ name: 'privacy-en' }">Privacy Policy</router-link>
      and the end user license agreement or other terms of use that govern your use of the Acura EV app including
      Honda's <a class="link" target="_blank" href="https://www.honda.com/privacy/privacy-notice">Privacy Notice</a>.
      Your mobile carrier’s messaging, data, and other rates and fees may apply to your use of the Acura EV app.
    </p>
    <p>
      Electrify America and Acura will each collect certain personal information in connection with your participation
      in the Promotional Plan. Each of Electrify America and Acura will use the personal information it collects in
      accordance with its respective terms and privacy disclosures. You authorize and direct Electrify America and Acura
      to disclose your personal information to each other as reasonably necessary to provide and support the Promotional
      Plan permitted by the receiving party’s privacy disclosures. Notwithstanding, for sake of clarity, each party’s
      privacy disclosures shall govern all collection, processing, use, and disclosure.
    </p>
    <p>
      Immediately upon your registration and acceptance of these Promotional Plan Terms, you will be enrolled in a
      program that provides credit for a total of 60 kWh, or per-minute equivalent, of DC fast (CCS) and Level 2
      charging with access to the Electrify America network during the 36 months from the date of vehicle purchase or
      lease. You may redeem this credit on Electrify America’s network of electric vehicle charging stations.
    </p>
    <p>
      After signing up, you can redeem your complimentary charging during your initiation of a charging session at any
      Electrify America charging station exclusively by using the Acura EV app “Tap to Charge” feature with the
      appropriate plan selected. This complimentary charging may not be initiated using the interface screen or buttons
      on the charger.
    </p>
    <p>
      There is no additional fee, purchase, or subscription required for your access to this Promotion Plan, but an
      active credit card must be associated with your account during the term of the Promotional Plan. Subject to the
      restrictions below and the limitation on usage above, there is no limit on the number of eligible charging
      sessions.
    </p>
    <p class="primary-color">
      <strong>PLEASE NOTE THE FOLLOWING IMPORTANT LIMITATIONS ON YOUR USE OF THIS PROMOTION:</strong>
    </p>
    <p>
      You may <strong>only</strong> use this Promotional Plan for charging a new Acura battery electric vehicle whose
      VIN you submitted when signing up for this promotion.
      <strong>No other vehicle is eligible to receive this credit through your account</strong>, whether owned by you or
      others. This Promotional Plan is for your personal, private use only and you may not use this promotion benefit
      for commercial purposes, including but not limited to rideshare or fleet use.
    </p>
    <p>
      Complimentary charging time <strong>does not include</strong> any idle fees or applicable taxes assessed at the
      charging station. Electrify America will initially pre-authorize your account for $20 by placing a hold for this
      amount on the credit card associated with your Electrify America account at the start of each charging session to
      cover these potential charges in the event that they apply. If you exceed your available promotional charging
      credit prior to completion of a charging session, the charging session will continue and you will be charged for
      the use in excess of the available charging credit. You will be responsible for paying all fees through your
      account if you incur them during a charging session. There is a
      <strong>There is a 60-minute waiting period between eligible charging sessions</strong>, and the promotion
      benefits will not apply to a charging session initiated within this period.
    </p>
    <p>
      You must follow all product, vehicle, safety, and technical documentation included with the vehicle when charging
      at an Electrify America charging station.
    </p>
    <p>
      You must use the correct connector type and charge at the correct power level for the vehicle at the charging
      station.
    </p>
    <p>
      Once the term of this Promotional Plan has terminated or expired, you will not be able to reload, renew, or add
      more credit to this Promotional Plan. Please refer to the information on the
      <strong>“Plan Details”</strong> section of the Acura EV app to keep track of the amount of time remaining in this
      Promotional Plan.
    </p>
    <p>
      Promotional charging credit is not returnable or redeemable for any cash value, and may not be sold, loaned, or
      otherwise distributed to any third party for any reason. You may not transfer any portion of the credit to any
      other Electrify America account.
    </p>
    <p>
      You acknowledge that your use of Electrify America’s services is subject to the Electrify America Terms of Use
      available at
      <router-link class="link" :to="{ name: 'terms-en' }">https://www.electrifyamerica.com/terms</router-link>
      and that your use of the app is of subject to the Terms of Use accessible by navigating to your Electrify America
      account, selecting <strong>“Legal”</strong> and then selecting <strong>“Terms of Use”</strong> &
      <strong>“Privacy Policy.”</strong>
      Please read these Terms of Use documents carefully for information about how you can pay through your account for
      idle fees and applicable taxes as well as for charging session time outside of the complimentary charging offered
      under this promotion.
    </p>
    <p>
      You agree to comply with all applicable laws and regulations when using Electrify America services and when
      charging your vehicle at Electrify America charging stations.
    </p>
    <p>
      Electrify America reserves the right to withhold, revoke, reduce, terminate, or suspend your access to all or any
      portion of this promotion, without notice, if Electrify America determines or suspects, in its sole discretion,
      that you: (a) are in violation of the Electrify America Terms of Use, these Promotion Terms and Conditions, the
      Acura EV end user license agreement or other terms of use, or any other contract between you and Electrify America
      or Honda; (b) have engaged in charging sessions that are excessive, interfere with others’ reasonable use of the
      Electrify America network, fraudulent, or that otherwise indicate an unauthorized or unintended use of this
      complimentary promotion; or (c) have engaged in any illegal, fraudulent, tortious, injurious, harmful, or abusive
      conduct in your use of the Electrify America services, the Acura EV app, or an Electrify America charging station.
    </p>
    <p>
      Following the termination or expiration of this Promotional Plan for any reason your access to the complimentary
      promotion will immediately cease, and you may not use or redeem any remaining portion of the complimentary
      charging time. At that time, your account will automatically convert to a basic Electrify America “Pass” account,
      at no additional fee or charge to you (neither one-time nor recurring). You may, but are not obligated to, sign up
      for a different type of subscription via the Acura EV app.
    </p>
  </div>
</template>

<script>
export default {
  name: 'acura-charging-plan-disclosure',
  metaInfo: {
    title: 'Acura Charging Plan Disclosure | Electrify America',
    link: [{ rel: 'canonical', href: 'https://www.electrifyamerica.com/acura-charging-plan-disclosure/' }],
  },
};
</script>
